import { AdminBookingsHandlerPayload } from '../pages/api/admin-bookings'
import { AdminLoginHandlerPayload } from '../pages/api/admin-login'
import { BookHandlerPayload } from '../pages/api/book'
import { CarsHandlerPayload } from '../pages/api/cars'
import { PriceHandlerPayload } from '../pages/api/price'
import { RatesHandlerPayload } from '../pages/api/rates'
import { SendVerificationHandlerPayload } from '../pages/api/send-verification'
import { SubmitReviewHandlerPayload } from '../pages/api/submit-review'
import { Response } from '../utils/handlerWrapper'

export enum GeneralErrorCode {
  REQUEST_TYPE = 100,
  QUERY_VALIDATION_ERROR,
  BODY_VALIDATION_ERROR,
  NOT_FOUND,
}

export enum DataErrorCode {
  PHONE_NUMBER = 200,
  VERIFICATION_NOT_FOUND,
  CODE_EXPIRED,
  CAR_NOT_FOUND,
  CAR_NOT_AVAILABLE,
  START_AND_END_REQUIRED,
  BOOKING_NOT_FOUND,
}

// /price

export interface PriceHandlerQuery {
  startDate: string
  endDate: string
  carId: string
}
export type PriceHandlerResponse = Response<PriceHandlerPayload>

// /rates

export type RatesHandlerResponse = Response<RatesHandlerPayload>

// /cars

export interface CarsHandlerQuery {
  startDate: string
  endDate: string
}
export type CarsHandlerResponse = Response<CarsHandlerPayload>

// /book

export interface BookHandlerBody {
  // pickupDate: string
  // returnDate: string
  // location: string
  // name: string
  // email: string
  phone: string
  carName: string
  // code: string
  // carId: string
  // locale: string
}
export type BookHandlerResponse = Response<BookHandlerPayload>

// /send-verification

export interface SendVerificationHandlerBody {
  phone: string
  locale: string
}
export type SendVerificationHandlerResponse = Response<SendVerificationHandlerPayload>

// /submit-review

export interface SubmitReviewHandlerBody {
  text: string
  bookingId: string
  verification: string
}
export type SubmitReviewHandlerResponse = Response<SubmitReviewHandlerPayload>

// /admin-login

export interface AdminLoginHandlerBody {
  username: string
  password: string
}
export type AdminLoginHandlerResponse = Response<AdminLoginHandlerPayload>

// /admin-bookings

export interface AdminBookingsHandlerQuery {
  start: string
  end: string
}
export type AdminBookingsHandlerResponse = Response<AdminBookingsHandlerPayload>
