import Link from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ArticleDescription, Thumbnail } from './styles'
import Box from '../Box'
import Button from '../Button'
import Typography from '../Typography'

export interface Article {
  slug: string
  title: string
  description: string
  thumbnail: string
}

interface ArticlePreviewProps {
  article: Article
}

const ArticlePreview: React.FC<ArticlePreviewProps> = ({ article }) => {
  const { slug, title, description, thumbnail } = article

  const { t } = useTranslation('common')

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Thumbnail style={{ backgroundImage: `url(${thumbnail})` }} />
      <Typography.H3>{title}</Typography.H3>
      <ArticleDescription>{description}</ArticleDescription>
      <Link href={`/blog/${slug}`} passHref>
        <Button secondary>{t('read')}</Button>
      </Link>
    </Box>
  )
}

export default ArticlePreview
