import styled from "styled-components"

interface BoxProps {
  padding?: "small" | "large"
}

const Box = styled.div<BoxProps>`
  padding: ${({ padding = "small" }) => (padding === "small" ? 15 : 30)}px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  background-color: white;
`

export default Box
