import Image from 'next/image'
import styled from 'styled-components'

import Container from '../Container'
import Typography from '../Typography'

export const StyledContainer = styled(Container)`
  padding-top: 25px;
  padding-bottom: 50px;
`

export const StyledTitle = styled(Typography.H2)`
  margin-bottom: 30px;
`

export const QuestionSides = styled.div`
  display: flex;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`

export const QuestionsSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-right: 15px;

    @media (max-width: 850px) {
      margin-right: 0px;
    }
  }
`

export const StyledAnswer = styled(Typography.P)`
  transition: 0.3s;
  padding-top: 15px;
  opacity: 0.25;

  @media (max-width: 768px) {
    opacity: 1;
  }

  a {
    text-decoration: underline;
    &:hover {
      color: #4d5eff;
    }
  }
`

export const QuestionContainer = styled.div<{ isOpened: boolean }>`
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 15px;
  background-color: white;
  border-color: #c7c8df;
  margin-bottom: 30px;

  &:hover ${StyledAnswer} {
    opacity: 1;
  }
`

export const QuestionPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const QuestionTitle = styled(Typography.H3)`
  max-width: calc(100% - 60px);
`

export const StyledImage = styled(Image)<{ isOpened: boolean }>`
  transition: 0.3s ease-in-out;
  transform: rotate(${({ isOpened }) => (isOpened ? -90 : 90)}deg);
`
