import { useState } from "react"

const useModal = () => {
  const [visible, setVisible] = useState(false)

  const close = () => setVisible(false)
  const open = () => setVisible(true)

  return { visible, open, close }
}

export default useModal 
