import { Car } from '@prisma/client'
import * as Sentry from '@sentry/nextjs'
import va from '@vercel/analytics'
import { GetStaticProps } from 'next'
import Head from 'next/head'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ArticlePreview from '../components/ArticlePreview'
import BookingModal from '../components/BookingModal'
import Cars from '../components/Cars'
import { CarsGrid, StyledContainer } from '../components/Cars/styled'
import FAQ from '../components/FAQ'
import Footer from '../components/Footer'
import Gallery from '../components/Gallery'
import Header from '../components/Header'
import Jumbotron from '../components/Jumbotron'
import Reviews from '../components/Reviews'
import Typography from '../components/Typography'
import useCars from '../hooks/useCars'
import useModal from '../hooks/useModal'
import blog from '../services/blog'
import { prisma } from '../services/db'

const Home = ({ initialCars, reviews, articles, locale }) => {
  const { t } = useTranslation('common')
  const [selectedCar, selectCar] = useState(null)
  const { cars, fetchAvailableCars, isFetchingCars, lastSearchedDates } = useCars({
    initialCars,
  })
  const { visible, open, close } = useModal()
  const [galleryImages, setGalleryImages] = useState({ images: [], index: 0 })

  const openBookingModal = (car: Car) => {
    va.track('Opened Booking Modal', { car: car.name, locale })
    selectCar(car)
    open()
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <title>{t('title')} | DreamCar</title>
        <meta name="description" content={t('index.description')} />
        <meta name="keywords" content={t('index.keywords')} />
        <meta name="yandex-verification" content="f12e1dbbad31fb5b" />
      </Head>
      {visible && (
        <BookingModal
          selectedCar={selectedCar}
          close={close}
          lastSearchedDates={lastSearchedDates}
        />
      )}
      {!!galleryImages?.images?.length && (
        <Gallery
          images={galleryImages.images}
          initialIndex={galleryImages?.index}
          close={() => setGalleryImages({ images: [], index: 0 })}
        />
      )}
      <Header hasReviews />
      <Jumbotron fetchAvailableCars={fetchAvailableCars} isFetchingCars={isFetchingCars} />
      <Cars cars={cars} openBookingModal={openBookingModal} setGalleryImages={setGalleryImages} />
      {!!articles.length && (
        <StyledContainer>
          <Typography.H2>{t('blog')}</Typography.H2>
          <CarsGrid>
            {articles.map((a) => (
              <ArticlePreview key={a.slug} article={a} />
            ))}
          </CarsGrid>
        </StyledContainer>
      )}
      <Reviews reviews={reviews} />
      <FAQ />
      <Footer />
    </>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const loadTransaction = Sentry.startTransaction({
    name: 'Index Page - Data Loading',
  })

  const cars = await prisma.car.findMany()
  const reviews = await import('../utils/reviews.json')

  loadTransaction.finish()

  return {
    props: {
      reviews: reviews.default,
      articles: blog.getArticlesByLanguage(locale, 4).map((b) => ({ ...b, Component: null })),
      initialCars: cars.map((c) => ({
        ...c,
        createdAt: null,
        updatedAt: null,
      })),
      locale,
      ...(await serverSideTranslations(locale, ['common'])),
    },
    revalidate: 60 * 60 * 24, // 24h
  }
}

export default Home
