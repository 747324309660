import styled from 'styled-components'

import Typography from '../Typography'

export const Thumbnail = styled.div`
  margin: -15px -15px 15px;
  height: 300px;
  width: calc(100% + 30px);
  background-size: cover;
  background-position: center;
  border-radius: 5px 5px 0 0;
`

export const ArticleDescription = styled(Typography.P)`
  margin: 15px 0;
  flex: 1;
`
