import styled from 'styled-components'

import Button from '../Button'

export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

export const FooterButton = styled(Button)`
  width: 130px;
`

export const FooterTotal = styled.div`
  flex: 1;
`

export const Prices = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
