import { Car as CarType } from '@prisma/client'
import NextImage from 'next/image'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DashContainer,
  Dash,
  FeatureContainer,
  Features,
  ImageContainer,
  GalleryCTA,
  NavButton,
  ThumbnailContainer,
} from './styled'
import chevronIcon from '../../assets/Chevron.svg'
import engineIcon from '../../assets/EngineIcon.svg'
import galleryIcon from '../../assets/Gallery.svg'
import gearIcon from '../../assets/GearIcon.svg'
import makeIcon from '../../assets/MakeIcon.svg'
import usePreferedCurrencyContext from '../../hooks/usePreferedCurrency'
import Box from '../Box'
import Button from '../Button'
import Typography from '../Typography'

export interface CarWithPrice extends CarType {
  price?: {
    total: number
    discount: number
  }
}

interface CarProps {
  car: CarWithPrice
  onButtonClick: () => void
  openGallery: (index: number) => void
}

const Car: React.FC<CarProps> = ({ car, onButtonClick, openGallery }) => {
  const { t } = useTranslation('common')

  const mapGearTypeToName = (gearType: number) => {
    switch (gearType) {
      case 1:
        return t('automatic')
      case 2:
        return t('manual')
      default:
        return ''
    }
  }

  const mapEngineTypeToName = (engineType: number) => {
    switch (engineType) {
      case 1:
        return t('petrol')
      case 2:
        return t('diesel')
      default:
        return ''
    }
  }

  const { convertToPreferedCurrency, isLoadingRates } = usePreferedCurrencyContext()
  const [imageIndex, setImageIndex] = useState(0)

  const placeholders = [...car.images.slice(0, 3), car.images[2]]

  const convertedPrice = convertToPreferedCurrency(car.longterm_price)

  const convertedCalculatedPrice = convertToPreferedCurrency(car.price?.total || 0)

  const prevImage = () => setImageIndex((prev) => prev - 1)
  const nextImage = () => setImageIndex((prev) => prev + 1)

  return (
    <Box>
      <ImageContainer>
        {placeholders.length > 1 && (
          <DashContainer>
            {placeholders.map((_, index) => (
              <Dash key={index} active={index === imageIndex} />
            ))}
          </DashContainer>
        )}
        {placeholders.map((image, index) => (
          <ThumbnailContainer
            key={image}
            visible={index === imageIndex}
            onClick={() => openGallery(index)}
          >
            <NextImage src={image} alt="Car Image" fill />
          </ThumbnailContainer>
        ))}
        {imageIndex === 3 && (
          <GalleryCTA onClick={() => openGallery(3)}>
            <NextImage {...galleryIcon} width={50} height={50} />
            <Typography.H4>
              {t('more_photos')} (+{car?.images?.length - 3})
            </Typography.H4>
          </GalleryCTA>
        )}
        {imageIndex !== 0 && (
          <NavButton onClick={prevImage} rotate>
            <NextImage {...chevronIcon} height={20} width={20} alt="Previous Image" />
          </NavButton>
        )}
        {imageIndex < placeholders.length - 1 && (
          <NavButton onClick={nextImage}>
            <NextImage {...chevronIcon} height={20} width={20} alt="Next Image" />
          </NavButton>
        )}
      </ImageContainer>
      <Typography.H3>{car.name}</Typography.H3>
      <Features>
        <FeatureContainer>
          <NextImage {...makeIcon} alt="Year" />
          <Typography.Default>{car.year}</Typography.Default>
        </FeatureContainer>
        <FeatureContainer>
          <NextImage {...gearIcon} alt="Gear" />
          <Typography.Default>{mapGearTypeToName(car.gearType)}</Typography.Default>
        </FeatureContainer>
        <FeatureContainer>
          <NextImage {...engineIcon} alt="Engine" />
          <Typography.Default>
            {car.engineVolume?.toFixed(1)}, {mapEngineTypeToName(car.engineType)}
          </Typography.Default>
        </FeatureContainer>
      </Features>
      <Button onClick={onButtonClick} isLoading={isLoadingRates}>
        {convertedCalculatedPrice?.number
          ? `${t('book_now_for')} ${convertedCalculatedPrice.number} ${
              convertedCalculatedPrice.currency
            }`
          : `${t('book_now_from')} ${convertedPrice?.number} 
        ${convertedPrice?.currency}/${t('day')}`}
      </Button>
    </Box>
  )
}

export default Car
