import Image from 'next/image'
import styled from 'styled-components'

interface OverlayProps {
  visible?: boolean
}

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .7);
  z-index: 2;
  backdrop-filter: blur(3px);
`

export const ModalContainer = styled.div`
  max-height: 100vh;
  width: 100%;
  max-width: 510px;
  background-color: white;
  border-radius: 5px;
`

export const ModalHeader = styled.div`
  padding: 20px 30px;
  border-bottom: 1px solid #C7C8DF;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    max-width: calc(100% - 60px);
  }
`

export const CloseImage = styled(Image)`
  cursor: pointer;
`

export const ModalContent = styled.div`
  flex: 1;
  padding: 30px;
  overflow-y: scroll;
  max-height: calc(100vh - 68.5px);
`
