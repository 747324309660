import { yupResolver } from '@hookform/resolvers/yup'
import { Car } from '@prisma/client'
import { format } from 'date-fns'
import * as datesLocales from 'date-fns/locale'
import { utcToZonedTime } from 'date-fns-tz'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { Footer, FooterButton, FooterTotal, Prices } from './styled'
import useBookCar from '../../hooks/useBookCar'
import usePreferedCurrency from '../../hooks/usePreferedCurrency'
import { testPhoneNumber, testFormRange } from '../../services/yup'
import CodeInput from '../CodeInput'
import Input from '../Input'
import Modal from '../Modal'
import PhoneInput from '../PhoneInput'
import RangePicker from '../RangePicker'
import Typography from '../Typography'

interface IForm {
  // range: [string, string]
  // location: string
  // name: string
  // email: string
  phone: string
  // code?: string
}

interface BookingModalProps {
  selectedCar: Car | null
  close: () => void
  lastSearchedDates: [string, string] | [null, null]
}

const BookingModal: React.FC<BookingModalProps> = ({ selectedCar, close, lastSearchedDates }) => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const { convertToPreferedCurrency } = usePreferedCurrency()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm<IForm>({
    resolver: yupResolver(
      yup
        .object({
          // range: yup.array().test(testFormRange(t)),
          // location: yup.string().required(t('required')),
          // name: yup.string().required(t('required')),
          // email: yup.string().email().required(t('required')),
          phone: yup.string().test(testPhoneNumber(t)),
          // code: yup.string(),
        })
        .required()
    ),
    // defaultValues: {
    //   range: lastSearchedDates,
    // },
  })

  const {
    isLoading,
    verificationSent,
    sendVerificaton,
    verifyBooking,
    calculatePrices,
    price,
    booked,
  } = useBookCar({ setError })

  useEffect(() => {
    if (lastSearchedDates) {
      calculatePrices(lastSearchedDates, selectedCar?.id)
    }
  }, [calculatePrices, lastSearchedDates, selectedCar?.id])

  const onSubmit = (values: IForm) => {
    // if (!verificationSent) {
    //   return sendVerificaton(values.phone)
    // }

    // const { range, code, ...rest } = values

    verifyBooking({
      phone: values.phone,
      carName: selectedCar.name,
    })
  }

  const converedSum = convertToPreferedCurrency((price?.total || 0) + (price?.discount || 0))
  const converedTotal = convertToPreferedCurrency(price?.total)
  const converedDiscount = convertToPreferedCurrency(price?.discount)

  if (booked) {
    // const dateLocale = router.locale === 'ge' ? 'en' : router.locale
    // const dateFormat = router.locale === 'ru' ? 'd MMM HH:mm' : 'd MMM h:mm aa'

    // const range = getValues('range')
    // const start = format(utcToZonedTime(range[0], 'Asia/Tbilisi'), dateFormat, {
    //   locale: datesLocales[dateLocale],
    // })
    // const end = format(utcToZonedTime(range[1], 'Asia/Tbilisi'), dateFormat, {
    //   locale: datesLocales[dateLocale],
    // })

    return (
      <Modal title={`${t('success')}!`} close={close}>
        <Typography.Default>{t('thanks')}</Typography.Default>
      </Modal>
    )
  }

  return (
    <Modal title={`${selectedCar?.name} ${selectedCar?.year}`} close={close}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <Controller
          control={control}
          name="range"
          render={({ field }) => (
            <RangePicker
              error={errors.range as unknown as FieldError}
              startLabel={t('pickup')}
              endLabel={t('return')}
              disabled={verificationSent}
              {...field}
              onChange={(e) => {
                calculatePrices(e.target.value, selectedCar.id)
                field.onChange(e)
              }}
            />
          )}
        /> */}
        {/* <Input
          label={t('pickup_location')}
          error={errors.location}
          placeholder={t('airport')}
          disabled={verificationSent}
          {...register('location')}
        /> */}
        {/* <Input
          label={t('name')}
          error={errors.name}
          placeholder={t('your_name')}
          disabled={verificationSent}
          {...register('name')}
        /> */}
        {/* <Input
          label="Email"
          error={errors.email}
          placeholder="email@example.com"
          disabled={verificationSent}
          {...register('email')}
        /> */}
        <Controller
          control={control}
          name="phone"
          render={({ field }) => (
            <PhoneInput
              error={errors.phone}
              disabled={verificationSent}
              label={t('phone')}
              {...field}
            />
          )}
        />
        {/* {verificationSent && (
          <CodeInput
            label={t('code')}
            error={errors.code}
            placeholder="123456"
            {...register('code')}
            resend={() => sendVerificaton(getValues('phone'))}
            maxLength={6}
          />
        )} */}
        {!!price?.discount && (
          <Prices>
            <Typography.Default>
              {t('rent_price')} - {converedSum?.number} {converedSum?.currency}
            </Typography.Default>
            <Typography.Default>
              {t('multiple_discount')} - {converedDiscount?.number} {converedDiscount?.currency}
            </Typography.Default>
          </Prices>
        )}
        <Footer>
          <FooterTotal>
            {price && (
              <Typography.Default>
                {t('total')}:{' '}
                <b>
                  {converedTotal?.number} {converedTotal?.currency}
                </b>
              </Typography.Default>
            )}
          </FooterTotal>
          <FooterButton type="submit" isLoading={isLoading}>
            {verificationSent ? t('confirm') : t('book')}
          </FooterButton>
        </Footer>
      </form>
    </Modal>
  )
}

export default BookingModal
