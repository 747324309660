import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import JumbotronBox from './JumbotronBox'
import {
  BlockContainer,
  StyledContainer,
  JumbotronIllustrationDesktop,
  ContentContainer,
} from './styled'
import jumbotronIllustration from '../../assets/JumbotronIllustration.svg'
import Typography from '../Typography'

const Subtitle = styled(Typography.Subtitle)`
  margin-top: 20px;
  max-width: 650px;
  color: #1a1a41;
  line-height: 24px;
`

interface IForm {
  range: [string, string]
}

interface JumbotronProps {
  fetchAvailableCars: (startDate: string, endDate: string) => Promise<boolean>
  isFetchingCars: boolean
}

const Jumbotron: React.FC<JumbotronProps> = ({ fetchAvailableCars, isFetchingCars }) => {
  const { t } = useTranslation('common')
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const router = useRouter()
  const [fetchedInitial, setFetchedInitial] = useState(false)

  const initialValues = useMemo(
    () =>
      router.query && router.query.start && router.query.end
        ? ([router.query.start, router.query.end] as [string, string])
        : undefined,
    [router.query]
  )

  const onSubmit = useCallback(
    async ({ range }: IForm) => {
      const hasCars = await fetchAvailableCars(range[0], range[1])

      if (hasCars) {
        location.hash = ''
        location.hash = '#cars'
        return
      }

      setTooltipVisible(true)
      setTimeout(() => {
        setTooltipVisible(false)
      }, 2000)
    },
    [fetchAvailableCars]
  )

  useEffect(() => {
    if (initialValues && !fetchedInitial) {
      onSubmit({ range: initialValues })
      setFetchedInitial(true)
    }
  }, [fetchedInitial, initialValues, onSubmit])

  return (
    <BlockContainer>
      <StyledContainer>
        <ContentContainer>
          <Typography.H1>{t('title')}</Typography.H1>
          <Subtitle>{t('index.description')}</Subtitle>
          {/* <JumbotronBox
            onSubmit={onSubmit}
            isTooltipVisible={tooltipVisible}
            isLoading={isFetchingCars}
            initialValues={initialValues}
          /> */}
        </ContentContainer>
        <JumbotronIllustrationDesktop>
          <Image fill src={jumbotronIllustration.src} alt="Batumi Illustration" />
        </JumbotronIllustrationDesktop>
      </StyledContainer>
    </BlockContainer>
  )
}

export default Jumbotron
