import styled from 'styled-components'

import Container from '../Container'
import Typography from '../Typography'

export const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 25px;
`

export const StyledSubtitle = styled(Typography.Subtitle)`
  margin-top: 5px;
`

export const CarsGrid = styled.div<{ lgRepeat?: number }>`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: ${({ lgRepeat = 4 }) => `repeat(${lgRepeat}, 1fr)`};
  margin-top: 30px;

  @media (max-width: 1335px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 993px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }
`
