import styled from 'styled-components'

import Box from '../Box'
import Button from '../Button'
import Container from '../Container'

export const BlockContainer = styled.div`
  width: 100%;
  height: 544px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafb;

  @media (max-width: 1230px) {
    height: auto;
  }
`

export const StyledContainer = styled(Container)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1230px) {
    align-items: center;
    padding-top: 50px;
    padding-bottom: 450px;
  }

  @media (max-width: 630px) {
    padding-bottom: calc(100vw / 1.526 + 30px);
  }
`

export const StyledBox = styled(Box)`
  width: fit-content;
  margin-top: 30px;

  @media (max-width: 610px) {
    width: 100%;
  }
`

export const FormContainer = styled.form`
  display: flex;
  align-items: flex-start;

  @media (max-width: 610px) {
    width: 100%;
    flex-direction: column;
  }
`

export const SearchButton = styled(Button)`
  width: 125px !important;

  @media (max-width: 610px) {
    width: 100% !important;
  }
`

export const SearchButtonContainer = styled.div`
  position: relative;
  margin-left: 30px;
  margin-top: 28px;

  @media (max-width: 610px) {
    margin-left: 0;
    width: 100%;
  }
`

export const Tooltip = styled.div<{ isVisible: boolean }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -45px;
  white-space: nowrap;
  padding: 10px;
  font-size: 16px;
  color: #1a1a41;
  background-color: #fafafb;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;

  ${({ isVisible }) =>
    isVisible
      ? `
    opacity: 1;
    visibility: visible;
  `
      : ''}
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1400px) {
    width: 550px;
  }

  @media (max-width: 1270px) {
    width: 500px;
  }

  @media (max-width: 1230px) {
    width: 600px;
  }

  @media (max-width: 630px) {
    width: 100%;
  }
`

export const JumbotronIllustrationDesktop = styled.div`
  position: absolute;
  right: 15px;
  bottom: 20px;
  height: 504px;
  width: 770px;
  z-index: 0;

  @media (max-width: 1230px) {
    width: 600px;
    height: 392px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  @media (max-width: 630px) {
    height: auto;
    aspect-ratio: 1.53;
    width: calc(100% - 30px);
  }
`
