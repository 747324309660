import React from 'react'
import { FieldError } from 'react-hook-form'
import RPhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'
import { Container, ErrorMessage, Label } from './styled'

interface PhoneInputProps {
  label?: string
  value: string
  onChange: (phone: string) => void
  error?: FieldError
  disabled?: boolean
}

const PhoneInput: React.FC<PhoneInputProps> = ({ value, onChange, label, error, disabled }) => {
  return (
    <Container withError={!!error}>
      {label && <Label>{label}</Label>}
      <RPhoneInput country="ge" value={value} onChange={onChange} disabled={disabled} />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Container>
  )
}

export default PhoneInput
