import styled from "styled-components"

interface ContainerProps {
  withError?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .react-tel-input {
    font-family: Ubuntu, sans-serif;
  }

  .form-control {
    width: 100%;
    height: 45px;
    padding-left: 60px;
    font-size: 16px;
    border-color: #C7C8DF;

    ${({ withError }) => withError ? 'border-color: #FF4D57 !important;' : ''}
  }

  .flag-dropdown  {
    width: 45px;
  }

  .selected-flag {
    width: 43px;
    padding-left: 13.5px;
    border-radius: 5px 0 0 5px !important;

    &:hover {
      background: transparent;
    }
  }

  .flag-dropdown {
    border-radius: 5px 0 0 5px !important;
  }

  .flag {
    transform: scale(1.2);
  }

  .arrow {
    display: none;
  }

  .country-list {
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }

  .flag-dropdown {
    border-color: #C7C8DF;
    border-radius: 5px 0 0 5px;
    background-color: white;

    ${({ withError }) => withError ? 'border-color: #FF4D57 !important;' : ''}

    &:hover {
      background-color: #FAFAFB;
    }
  }

  .country.highlight, .country:hover {
    background-color: #FAFAFB !important;
  }
`

export const Label = styled.span`
  font-size: 16px;
  margin-bottom: 10px;
`

export const ErrorMessage = styled.span`
  margin: 5px 0 0;
  font-size: 14px;
  color: #FF4D57;
`
