import styled from 'styled-components'

export const ImageContainer = styled.div`
  position: relative;
  height: 300px;
  margin: -15px -15px 15px -15px;
`

export const ThumbnailContainer = styled.div<{ visible: boolean }>`
  height: 300px;
  width: 100%;
  position: relative;
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  img {
    object-fit: cover;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
  }
`

export const DashContainer = styled.div`
  position: absolute;
  top: 15px;
  width: calc(100% - 30px);
  left: 15px;
  display: flex;
  z-index: 1;
`

interface DashProps {
  active?: boolean
}

export const Dash = styled.div<DashProps>`
  flex: 1;
  height: 3px;
  margin-right: 3px;
  opacity: ${({ active }) => (active ? 1 : 0.2)};
  transition: 0.2s ease-in-out;
  background-color: white;
`

export const Features = styled.div`
  margin: 15px 0;
`

export const FeatureContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 15px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const GalleryCTA = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);

  h4 {
    color: white;
    margin-top: 15px;
    width: 220px;
    text-align: center;
  }
`

export const NavButton = styled.div<{ rotate?: boolean }>`
  position: absolute;
  top: 50%;
  right: 10px;
  width: 35px;
  height: 35px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ rotate }) =>
    rotate
      ? `
    transform: rotate(180deg);
    right: auto;
    left: 10px;
  `
      : ''}
`
