import styled from "styled-components"

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 2;
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

export const ImageContainer = styled.div`
  flex: 1;
  max-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Image = styled.img<{ visible: boolean }>`
  position: absolute;
  max-height: 100%;
  max-width: calc(100% - 160px);
  opacity: 0;
  visibility: hidden;

  ${({ visible }) => visible ? `
    opacity: 1;
    visibility: visible;
  ` : ''}

  @media (max-width: 820px) {
    max-width: 100%;
  }
`

export const ReelContainer = styled.div`
  position: relative;
  min-height: 100px;
  width: 100vw;
  border-top: 1px solid #C7C8DF;
  display: flex;
  align-items: center;s
`

export const Reels = styled.div<{ offset: number }>`
  transition: .3s ease-in-out;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(${({ offset }) => offset}px);
`

export const PreviewImage = styled.img<{ active: boolean }>`
  transition: .3s ease-in-out;
  width: 70px;
  height: 70px;
  border-radius: 5px;
  object-fit: cover; 
  cursor: pointer;
  opacity: ${({ active }) => active ? 1 : 0.55};

  &:not(last-child) {
    margin-right: 15px;
  }
`

export const GalleryButton = styled.button<{ rotate?: boolean, visible?: boolean }>`
  position: absolute;
  max-width: 50px;
  max-height: 50px;
  padding: 0;
  border: none;
  padding: 10px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  right: 15px;
  opacity: 0;
  transition: .3s ease-in-out;
  z-index: 1;

  ${({ rotate }) => rotate ? `
    transform: rotate(180deg);
    right: unset;
    left: 15px;
  ` : ''}

  ${({ visible }) => visible ? `
    opacity: 1;
  ` : ''}
`
