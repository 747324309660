import * as Sentry from '@sentry/nextjs'
import { useState } from "react"

import { queries } from "../services/api"

const useCars = ({ initialCars }) => {
  const [cars, setCars] = useState(initialCars)
  const [isLoading, setIsLoading] = useState(false)
  const [lastSearchedDates, setLastSearchedDates] = useState<[string, string] | [null, null]>([null, null])

  const fetchAvailableCars = async (startDate: string, endDate: string): Promise<boolean> => {
    setIsLoading(true)

    try {
      const response = await queries.getCars({ startDate, endDate })
      setIsLoading(false)

      if (!response?.data?.data?.length) {
        return false
      }

      setCars(response.data.data)
      setLastSearchedDates([startDate, endDate])

      return true
    } catch (err) {
      Sentry.captureEvent(err)
    } finally {
      setIsLoading(false)
    }
  }

  return { cars, fetchAvailableCars, isFetchingCars: isLoading, lastSearchedDates }
}

export default useCars
