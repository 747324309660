import NextImage from 'next/image'
import React, { useEffect, useState } from 'react'

import {
  ImageContainer,
  Overlay,
  ReelContainer,
  Image,
  PreviewImage,
  Reels,
  GalleryButton,
} from './styled'
import chevronIcon from '../../assets/Chevron.svg'

interface GalleryProps {
  images: string[]
  initialIndex: number
  close: () => void
}

const Gallery: React.FC<GalleryProps> = ({ images, initialIndex, close }) => {
  const [selectedIndex, setIndex] = useState(initialIndex)

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])

  const reelsOffset = -(35 + selectedIndex * 70 + selectedIndex * 15)

  const prevVisible = selectedIndex !== 0
  const nextVisible = selectedIndex !== images.length - 1

  const clickWithPrevent = (callback?: () => void) => (e) => {
    e.stopPropagation()
    callback?.()
  }

  const prevImage = () => {
    if (prevVisible) {
      setIndex((i) => i - 1)
    }
  }

  const nextImage = () => {
    if (nextVisible) {
      setIndex((i) => i + 1)
    }
  }

  return (
    <Overlay onClick={close}>
      <ImageContainer>
        <GalleryButton visible={prevVisible} rotate onClick={clickWithPrevent(prevImage)}>
          <NextImage {...chevronIcon} width={15} height={13} />
        </GalleryButton>
        {images.map((image, index) => (
          <Image
            key={index}
            src={image}
            visible={index === selectedIndex}
            onClick={clickWithPrevent()}
            alt={`Car Photo ${index + 1}`}
          />
        ))}
        <GalleryButton visible={nextVisible} onClick={clickWithPrevent(nextImage)}>
          <NextImage {...chevronIcon} width={15} height={13} />
        </GalleryButton>
      </ImageContainer>
      <ReelContainer>
        <Reels offset={reelsOffset}>
          {images.map((image, index) => (
            <PreviewImage
              active={index === selectedIndex}
              key={index}
              src={image}
              alt={`Image ${index}`}
              onClick={clickWithPrevent(() => setIndex(index))}
            />
          ))}
        </Reels>
      </ReelContainer>
    </Overlay>
  )
}

export default Gallery
