import * as Sentry from '@sentry/nextjs'
import va from '@vercel/analytics'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { queries } from '../services/api'
import { BookHandlerBody, DataErrorCode } from '../types/api'

interface UseBookCarParams {
  setError?: UseFormSetError<any>
}

const useBookCar = (params: UseBookCarParams) => {
  const { t } = useTranslation('common')

  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [verificationSent, setVerificationSent] = useState(false)
  const [booked, setBooked] = useState(false)
  const [price, setPrice] = useState<{ total: number; discount: number } | null>(null)

  const sendVerificaton = async (phone: string) => {
    setIsLoading(true)

    try {
      await queries.sendVerification({ phone, locale: router.locale })
      setVerificationSent(true)
    } catch (err) {
      Sentry.captureException(err)
    } finally {
      setIsLoading(false)
    }
  }

  const verifyBooking = async (data: BookHandlerBody) => {
    setIsLoading(true)

    try {
      await queries.bookCar(data)
      va.track('Sent Booking', { phone: data.phone, car: data.carName, locale: router.locale })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gtag?.('event', 'lead_form')
      setBooked(true)
    } catch (err) {
      console.log(err)
      if (err.response.data?.errorCode === DataErrorCode.CAR_NOT_AVAILABLE) {
        setVerificationSent(false)
        params.setError?.('range', {
          message: t('car_busy'),
        })
      }
      if (err.response.data?.errorCode === DataErrorCode.CODE_EXPIRED) {
        params.setError?.('code', {
          message: t('code_expired'),
        })
      }
      if (err.response.data?.errorCode === DataErrorCode.VERIFICATION_NOT_FOUND) {
        params.setError?.('code', {
          message: t('code_incorrect'),
        })
      }
      Sentry.captureException(err)
    } finally {
      setIsLoading(false)
    }
  }

  const calculatePrices = useCallback(
    async (range: [string, string], carId) => {
      if (!range[0] || !range[1]) {
        return
      }

      try {
        const response = await queries.getCarPriceForDates({
          startDate: range[0],
          endDate: range[1],
          carId,
        })
        setPrice(response.data.data)
      } catch (err) {
        Sentry.captureException(err)
        setPrice(null)
      }
    },
    [setPrice]
  )

  return {
    isLoading,
    verificationSent,
    booked,
    sendVerificaton,
    verifyBooking,
    calculatePrices,
    price,
  }
}

export default useBookCar
