import React, { useEffect } from 'react'

import { Overlay, ModalContainer, ModalContent, ModalHeader, CloseImage } from './styled'
import closeIcon from '../../assets/Close.svg'
import Typography from '../Typography'

interface ModalProps {
  close: () => void
  title: string
}

const Modal: React.FC<ModalProps> = ({ close, children, title }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])

  const handleContentClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()
  }

  return (
    <Overlay onClick={close}>
      <ModalContainer onClick={handleContentClick}>
        <ModalHeader>
          <Typography.H2>{title}</Typography.H2>
          <CloseImage {...closeIcon} height={30} width={30} alt="Close" onClick={close} />
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
      </ModalContainer>
    </Overlay>
  )
}

export default Modal
