import React from 'react'
import { useTranslation } from 'react-i18next'

import { CarsGrid, StyledContainer } from './styled'
import Car from '../Car'
import { CarWithPrice } from '../Car/Car'
import Typography from '../Typography'

interface CarsProps {
  cars: CarWithPrice[]
  openBookingModal?: (car: CarWithPrice) => void
  setGalleryImages: (payload: { images: string[]; index: number }) => void
}

const Cars: React.FC<CarsProps> = ({ cars, setGalleryImages }) => {
  const { t } = useTranslation('common')

  const mapIdToExtenrnal = (car: CarWithPrice) => {
    const map = {
      '62141c76cf15e20ee6d95264': 'clkgp9djw0000mq087k7u2kj7',
      '640b27624e2ee9985e2d049c': 'clkgpagbw0000me08efa01rl8',
      '640b292b4e2ee9985e2d049d': 'clkgpijli0001p9r7veh6vjza',
      '6483405849a551c323ef8820': 'clkgph0at0000p9r75484n3g6',
      '648340b649a551c323ef8821': 'clkgpb5ga0001mq084zhpf2en',
    }

    window.open(
      `https://www.tryautopilot.io/clka9v7oq0005l708ud7r492z/${map[car.id] || ''}`,
      '_blank'
    )
  }

  return (
    <StyledContainer id="cars">
      <Typography.H2>{t('cars')}</Typography.H2>
      <CarsGrid>
        {cars.map((car) => (
          <Car
            key={car.id}
            car={car}
            onButtonClick={() => mapIdToExtenrnal(car)}
            openGallery={(index) => setGalleryImages({ images: car.images, index })}
          />
        ))}
      </CarsGrid>
    </StyledContainer>
  )
}

export default Cars
