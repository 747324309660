import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { JSONLD, GenericCollection, Generic, Question, Answer } from 'react-structured-data'

import {
  StyledContainer,
  StyledTitle,
  QuestionContainer,
  StyledAnswer,
  QuestionSides,
  QuestionsSide,
  QuestionPanel,
  QuestionTitle,
} from './styled'

const questionsRU = [
  {
    title: 'Что нужно для аренды авто в Батуми?',
    answer: (
      <>
        Для аренды авто в Батуми нужен паспорт и{' '}
        <a
          href="https://ru.wikipedia.org/wiki/Венская_конвенция_о_дорожном_движении"
          target="_blank"
          rel="noreferrer"
        >
          водительские права международного образца
        </a>
      </>
    ),
    answerSimple:
      'Для аренды авто в Батуми нужен паспорт и водительские права международного образца',
  },
  {
    title: 'Где я могу получить автомобиль?',
    answer:
      'Вы можете получить арендованный автомобиль в любой точке города. Так же мы можем доставить арендованный авто в аэропорт Батуми',
  },
  {
    title: 'В какое время я могу получить автомобиль?',
    answer: 'Арендованный авто можно получить в любое время - мы работаем круглосуточно',
  },
  {
    title: 'На сколько я могу арендовать автомобиль?',
    answer:
      'Арендовать авто на любое количество дней, минимальная аренда - одни сутки. Например, можно арендовать на один день и несколько часов',
  },
  {
    title: 'Как я могу оплатить автомобиль?',
    answer: 'Наличными в лари (GEL) или же картой',
  },
  {
    title: 'Ограничен ли пробег?',
    answer: 'Нет, пробег в арендованном авто не ограничен',
  },
  {
    title: 'Застрахованы ли автомобили?',
    answer: 'Да, автомобили застрахованы от всех повреждений',
  },
  {
    title: 'Со скольки лет можно арендовать автомобиль?',
    answer: 'Вам должно быть не менее 21 чтобы арендовать авто в Батуми',
  },
  {
    title: 'Нужен ли депозит/залог?',
    answer: 'Нет, депозит и залог не нужны чтобы взять авто в аренду в Батуми',
  },
  {
    title: 'Где я могу парковаться?',
    answer:
      'В Грузии платная парковка только в Батуми, Кутаиси и Тбилиси. Все наши машины имеют абонемент на парковку в Батуми',
  },
  {
    title: 'В каком состоянии возвращать автомобиль?',
    answer: 'Вернуть арендованный автомобиль нужно в том же состоянии, в котором Вы его получили',
  },
  {
    title: 'Можете встретить меня в аэропорту?',
    answer:
      'Конечно! Для этого уточните номер рейса во время бронирования автомобиля. Наш сотрудник встретит вас с табличкой с Вашим именем',
  },
  {
    title: 'Могу ли я выехать за пределы Грузии?',
    answer: 'Нет, это не предусмотрено договором',
  },
  {
    title: 'Может ли другой водитель сесть за руль арендованного авто?',
    answer:
      'Да. Вам нужно предупредить нас заранее и предоставить документы (права, паспорт) всех водителей во время получения автомобиля',
  },
]

const questionsEN = [
  {
    title: 'What do I need to rent a car in Georgia?',
    answer: (
      <>
        You need a passport and driver&apos;s license (
        <a
          href="https://en.wikipedia.org/wiki/International_Driving_Permit"
          target="_blank"
          rel="noreferrer"
        >
          international
        </a>
        ) to rent a car in Georgia
      </>
    ),
    answerSimple:
      "You need a passport and driver's license (international) to rent a car in Georgia",
  },
  {
    title: 'Where can I get a car?',
    answer: 'You can get a can in any location in the city or at the Batumi Airport',
  },
  {
    title: 'What time can I get a car?',
    answer: 'Any time of the day',
  },
  {
    title: 'For how long can I rent a car?',
    answer:
      'For any amount of days, but at least for a day. For example, you can rent a car for a day and a couple of hours',
  },
  {
    title: 'How can I pay?',
    answer: 'By cash in lari (GEL) or by card',
  },
  {
    title: 'Is mileage limited?',
    answer: 'No, mileage is not limited',
  },
  {
    title: 'Are the cars insured?',
    answer: 'Yes, all cars insured from any type of accident',
  },
  {
    title: 'What is the minimum age to rent a car?',
    answer: 'The minimum age to rent a car is 21',
  },
  {
    title: 'Is deposit required?',
    answer: 'No, you don’t have to pay any deposit',
  },
  {
    title: 'Where can I park?',
    answer:
      'In Georgia, paid parking is only in Batumi, Kutaisi and Tbilisi. All our cars have a parking subscription in Batumi',
  },
  {
    title: 'In what condition do I have to return a car?',
    answer: 'You have to return a car in the same condition you got it',
  },
  {
    title: 'Can you meet me at the airport?',
    answer:
      'Sure! Just specify your flight number in the “location” field while booking. One of our employee will meet you with a sign with your name',
  },
  {
    title: 'Can I go outside Georgia?',
    answer: 'No, you can’t',
  },
  {
    title: 'Can another driver drive a car?',
    answer:
      'Yes. You need to notify us in advance and provide the documents (license, passport) of all drivers at the time of picking up the car',
  },
]

const questionsKA = [
  {
    title: 'რა მჭირდება საქართველოში მანქანის დასაქირავებლად?',
    answer: 'საქართველოში მანქანის დასაქირავებლად გჭირდებათ პასპორტი და მართვის მოწმობა',
  },
  {
    title: 'სად ვიშოვო მანქანა?',
    answer: 'შეგიძლიათ მიიღოთ ქილა ქალაქის ნებისმიერ ადგილას ან ბათუმის აეროპორტში',
  },
  {
    title: 'რომელ საათზე შემიძლია მანქანის ყიდვა?',
    answer: 'დღის ნებისმიერ დროს',
  },
  {
    title: 'რამდენი ხნით შემიძლია მანქანის დაქირავება?',
    answer:
      'ნებისმიერი დღის განმავლობაში, მაგრამ მინიმუმ ერთი დღის განმავლობაში. მაგალითად, შეგიძლიათ მანქანის დაქირავება ერთი დღით და რამდენიმე საათის განმავლობაში',
  },
  {
    title: 'როგორ გადავიხადო?',
    answer: 'ნაღდი ფულით ლარში (ლარში) ან ბარათით',
  },
  {
    title: 'გარბენი შეზღუდულია?',
    answer: 'არა, გარბენი შეზღუდული არ არის',
  },
  {
    title: 'მანქანები დაზღვეულია?',
    answer: 'დიახ, ყველა მანქანა დაზღვეულია ნებისმიერი ტიპის ავარიისგან',
  },
  {
    title: 'რა არის მინიმალური ასაკი მანქანის დაქირავებისთვის?',
    answer: 'მანქანის დაქირავებისთვის მინიმალური ასაკი არის 21',
  },
  {
    title: 'აუცილებელია დეპოზიტი?',
    answer: 'არა, თქვენ არ გჭირდებათ დეპოზიტის გადახდა',
  },
  {
    title: 'სად შემიძლია გაჩერება?',
    answer:
      'საქართველოში ფასიანი პარკინგი მხოლოდ ბათუმში, ქუთაისსა და თბილისშია. ჩვენს ყველა მანქანას აქვს პარკირების გამოწერა ბათუმში',
  },
  {
    title: 'რა მდგომარეობაში უნდა დავიბრუნო მანქანა?',
    answer: 'მანქანა უნდა დააბრუნო იმავე მდგომარეობაში, როგორშიც ის მიიღო',
  },
  {
    title: 'შეგიძლია დამხვდე აეროპორტში?',
    answer:
      'რა თქმა უნდა! უბრალოდ მიუთითეთ თქვენი ფრენის ნომერი „ადგილმდებარეობის“ ველში დაჯავშნისას. ჩვენი ერთ-ერთი თანამშრომელი შეგხვდებათ თქვენი სახელით წარწერით',
  },
  {
    title: 'შემიძლია წავიდე საქართველოს ფარგლებს გარეთ?',
    answer: 'არა, არ შეგიძლია',
  },
  {
    title: 'სხვა მძღოლს შეუძლია თუ არა მანქანის მართვა?',
    answer:
      'დიახ. თქვენ წინასწარ უნდა შეგვატყობინოთ და მოგვაწოდოთ ყველა მძღოლის საბუთები (ლიცენზია, პასპორტი) მანქანის აყვანის დროს',
  },
]

const QuestionComp = ({
  title,
  answer,
  isOpened,
  onClick,
}: {
  title: string
  answer: string | JSX.Element
  isOpened: boolean
  onClick: () => void
}) => (
  <QuestionContainer onClick={onClick} isOpened={isOpened}>
    <QuestionPanel>
      <QuestionTitle>{title}</QuestionTitle>
    </QuestionPanel>
    <StyledAnswer>{answer}</StyledAnswer>
  </QuestionContainer>
)

const FAQ: React.FC = () => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const [openedIndex, setOpenedIndex] = useState(-1)
  const [isMetaVisible, setIsMetaVisible] = useState(false)

  useEffect(() => setIsMetaVisible(true), [])

  const handleToggle = (index: number) => {
    if (openedIndex === index) {
      setOpenedIndex(-1)
      return
    }

    setOpenedIndex(index)
  }

  const questions =
    router.locale === 'ru' ? questionsRU : router.locale === 'ka' ? questionsKA : questionsEN

  const middle = Math.ceil(questions.length / 2)

  return (
    <StyledContainer id="faq">
      <StyledTitle>{t('faq')}</StyledTitle>
      {isMetaVisible && (
        <JSONLD>
          <Generic jsonldtype="FAQPage">
            <GenericCollection type="mainEntity">
              {questions.map((question, index) => (
                <Question key={index} name={question.title}>
                  <Answer type="acceptedAnswer" text={question.answerSimple || question.answer} />
                </Question>
              ))}
            </GenericCollection>
          </Generic>
        </JSONLD>
      )}
      <QuestionSides>
        <QuestionsSide>
          {questions.slice(0, middle).map((question, index) => (
            <QuestionComp
              key={index}
              title={question.title}
              answer={question.answer}
              isOpened={index === openedIndex}
              onClick={() => handleToggle(index)}
            />
          ))}
        </QuestionsSide>
        <QuestionsSide>
          {questions.slice(middle).map((question, index) => (
            <QuestionComp
              key={index}
              title={question.title}
              answer={question.answer}
              isOpened={middle + index === openedIndex}
              onClick={() => handleToggle(middle + index)}
            />
          ))}
        </QuestionsSide>
      </QuestionSides>
    </StyledContainer>
  )
}

export default FAQ
