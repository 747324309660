import styled from 'styled-components'

import Container from '../Container'
import Typography from '../Typography'

export const StyledContainer = styled(Container)`
  padding-top: 25px;
  padding-bottom: 25px;
`

export const ReviewsContainer = styled.div`
  margin: 30px -15px 0;

  > div > div > div > * {
    margin: 15px;
  }
`

export const ReviewHeader = styled.div`
  display: flex;
  margin-bottom: 15px;
  width: 100%;
`

export const ReviewHeaderMeta = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 100%;
`

export const ReviewHeaderMetaCarInfo = styled(Typography.Default)`
  margin-top: 5px;
  color: #4d5eff;
  letter-spacing: 2px;
`

export const CarImage = styled.div`
  height: 50px;
  width: 50px;
  min-width: 50px;
  position: relative;

  img {
    object-fit: cover;
    border-radius: 5px;
  }
`
export const HeaderRow = styled(Typography.H3)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
