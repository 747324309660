import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import {
  JSONLD,
  Product,
  AggregateRating,
  GenericCollection,
  Review,
  Author,
  Rating,
} from 'react-structured-data'

import {
  StyledContainer,
  ReviewsContainer,
  ReviewHeader,
  ReviewHeaderMeta,
  ReviewHeaderMetaCarInfo,
  CarImage,
  HeaderRow,
} from './styled'
import googleLogo from '../../assets/google.svg'
import Box from '../Box'
import Typography from '../Typography'

const Reviews: React.FC<{ reviews: any[] }> = ({ reviews }) => {
  const { t } = useTranslation('common')
  const [isMetaVisible, setIsMetaVisible] = useState(false)

  useEffect(() => setIsMetaVisible(true), [])

  const getOriginalText = (text: string) => {
    if (!text) return null

    const split = text.split(/(\(Translated by Google\)|\(Original\))/)
    const translationIndex = split.findIndex((val) => val === '(Translated by Google)') + 1
    const originalIndex = split.findIndex((val) => val === '(Original)') + 1

    return {
      original: split[originalIndex] || '',
      translated: translationIndex ? split[translationIndex] || '' : '',
    }
  }

  const getStars = (rating: number) => {
    return new Array(5)
      .fill(null)
      .map((_, index) => (index < rating ? '★' : '☆'))
      .join('')
  }

  if (!reviews?.length) {
    return null
  }

  return (
    <StyledContainer id="reviews">
      <Typography.H2>{t('reviews')}</Typography.H2>
      <ReviewsContainer>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 660: 2, 993: 3 }}>
          <Masonry>
            {reviews.map((review, index) => (
              <Box key={index}>
                <ReviewHeader>
                  <CarImage>
                    <Image src={review.author_image} fill alt={review.author_title} />
                  </CarImage>
                  <ReviewHeaderMeta>
                    <HeaderRow>
                      <span>
                        {review.author_title},{' '}
                        {review.review_datetime_utc.split(' ')[0].replaceAll('/', '.')}
                      </span>
                      <a
                        href={review.review_link}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="View on Google"
                      >
                        <Image {...googleLogo} width={20} height={20} alt="Google" />
                      </a>
                    </HeaderRow>
                    <ReviewHeaderMetaCarInfo>
                      {getStars(review.review_rating)}
                    </ReviewHeaderMetaCarInfo>
                  </ReviewHeaderMeta>
                </ReviewHeader>
                <Typography.P>{getOriginalText(review.review_text)?.original}</Typography.P>
                {getOriginalText(review.review_text)?.translated && (
                  <Typography.P>
                    <br />
                    <i>Translated by Google:</i>
                    <br />
                    {getOriginalText(review.review_text).translated}
                  </Typography.P>
                )}
              </Box>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </ReviewsContainer>
      {isMetaVisible && (
        <JSONLD>
          <Product name="Dream Car Rent - Car rental in Batumi">
            <AggregateRating ratingValue={5} reviewCount={reviews.length} />
            <GenericCollection type="review">
              {reviews.map((review) => (
                <Review
                  key={review.review_id}
                  reviewBody={review.review_text}
                  datePublished={review.review_datetime_utc}
                >
                  <Author name={review.author_title} />
                  <Rating ratingValue={review.rating} />
                </Review>
              ))}
            </GenericCollection>
          </Product>
        </JSONLD>
      )}
    </StyledContainer>
  )
}

export default Reviews
